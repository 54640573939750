.footer {
   background-color: #032030; 

 }
 .social-links a {
   color: #00fffc; 
   font-size: 1.5em;
   transition: color 0.3s;
 }
 
 .social-links a:hover {
   color: #0078ff; 
 }
 
 .scroll-to-top-icon {
   font-size: 2em;
   color: #00fffc;
   cursor: pointer;
   transition: color 0.3s;
 }
 
 .scroll-to-top-icon:hover {
   color: #0078ff; 
 }
 .footer[data-lightmode="true"] {
  background-color: #032030 !important; 
}