* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style-type: none;
    scroll-behavior: smooth;
    font-family: "Inter", sans-serif;
  }
  
  body {
    font-family: "oswald", sans-serif;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    transition: background-color 0.3s, color 0.3s;
    color: var(--text-color);
    overflow-x: hidden;
  }
  
  :root {
    --bg-color: #273A46; 
    --text-color: #F0F3FA;
    --h1-font: 5.6rem;
    --h2-font: 3.7rem sub;
    --h2-color: #B1C9EF;
    --h5-font: 1.5rem;
    --p-font: 1rem;
    --bg-header: #032030;
    --p--font-family:monospace;
  }

  
  .light-mode {
    --bg-color: #ffff;
    --text-color: #000000;
    --bg-header: #B1C9EF;
    
  }
  
  .dark-mode {
    --bg-color: var(--bg-color);
    --text-color: var(--text-color);
    --bg-color:#273A46
 
  }
  
  