/* Hero.css */
.hero {
  padding-top: 80px;
  background-color: var(--bg-color);
  padding: 2rem 0;
}
.hero .display-4 {
  font-size: 5rem;
}
.hero .fs-3 {
  font-size: 2rem !important;
  text-shadow: 3px 3px 5px #000000;
  font-weight: bolder !important;
}
.top-header {
  color: var(--h2-color);
  font-family: 'Solway', serif;
  letter-spacing: 1px;
  filter: drop-shadow(4px 3px 2px var(--h2-color));
  animation: mymove 7s infinite;
}

@keyframes mymove {
  50% {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}

.js-developer {
  color: #f39c12;
  font-weight: lighter;
  font-family: monospace;
}

.fullstack-developer {
  color: #27ae60;
  font-style: italic;
  font-weight: lighter;
  font-family: monospace;
}

.mobile-app-developer {
  color: #e74c3c;
  font-weight: lighter;
  font-family: monospace;
}

.hero[data-lightmode="true"] .display-4 {
  color: #294d61;
  text-shadow: 1px 3px 8px #ffff !important;
}

.hero[data-lightmode="true"] {
  background-image: url("../../Images/lighModeBg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.hero-img {
  width: 100%;
  height: auto;
  max-width: 700px;
  max-height: 700px;
}

@media (max-width: 1024px) {
  .hero-sec {
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
  }
  .hero-img {
    max-width: 250px;
    max-height: 250px;
  }
}

@media (max-width: 768px) {
  .hero .display-4 {
    font-size: 3.5rem;
  }
  .hero .fs-3 {
    font-size: 1.5rem !important;
  }
  .hero-img {
    max-width: 200px;
    max-height: 200px;
  }
}

@media (max-width: 576px) {
  .hero .display-4 {
    font-size: 2.5rem;
  }
  .hero .fs-3 {
    font-size: 1.2rem !important;
  }
  .hero-img {
    max-width: 250px;
    max-height: 300px;
  }
}
