.education-card {
    padding-top: 80px;
    background: linear-gradient(135deg, #032030 15%, #006494 85%);
    color: #fff;
  }
  
  .timeline-title {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
    position: relative;
  }
  
  .timeline-title::after {
    content: "";
    display: block;
    width: 50px;
    height: 4px;
    background: #00fffc;
    margin: 0.5rem auto;
    border-radius: 2px;
  }





  .timeline-items {
    border-left: 2px solid #00fffc;
    padding-left: 1rem;
  }
  
  .timeline-item {
    position: relative;
    padding-left: 2rem;
    margin-bottom: 2rem;
  }
  
  .timeline-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: -0.6rem;
    width: 1rem;
    height: 1rem;
    background: #00fffc;
    border: 2px solid #0078ff;
    border-radius: 50%;
  }
  
  .timeline-item h3 {
    font-size: 1.2em;
    margin-bottom: 0.5rem;
    color: #fff;
    text-transform: uppercase;
  }
  
  .timeline-item time {
    display: block;
    background: #0078ff;
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    font-size: 0.85em;
    margin-bottom: 0.5rem;
  }
  
  .timeline-item .location {
    font-size: 0.9em;
    font-style: italic;
    color: #00fffc;
  }
  
  @media (max-width: 768px) {
    .timeline-title {
      font-size: .8em;
    }
  
    .timeline-item h3 {
      font-size: .6em;
    }
  
    .timeline-item time {
      font-size: 0.7em;
    }
  
    .timeline-item .location {
      font-size: 0.7em;
    }
  }
  
  .education-card[data-lightmode="true"] {
  background:#ebf6f5;
  
}

  .education-card[data-lightmode="true"] .timeline-title{
  color: #FFFFFF;
  /* text-shadow: 1px 3px 6px #273A46 !important; */
  filter: drop-shadow(8px 8px 10px rgb(181, 180, 180));

}
  .education-card[data-lightmode="true"] h3{
  color: #4A8D88;
}
  .education-card[data-lightmode="true"] .location{
  color: #4A8D88;
  filter: brightness(1);
}
  .education-card[data-lightmode="true"] .timeline-title::after{
  background: #6DA5C0;
  width: 80px;
}
  .education-card[data-lightmode="true"] .timeline-item::before{
  background: #6DA5C0;
  border: 2px solid #8076a3;

}
  .education-card[data-lightmode="true"] .timeline-items{
  border-left: 2px solid #6DA5C0;
  
}
  .education-card[data-lightmode="true"] .timeline-item time{
 background:#8076a3;
  
}
