 .demos {
  background-color: var(--bg-color);
  padding: 30px;
}


.video-wrapper {
  width: 100%;
  max-width: 800px; 
  margin: 0 auto;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.video-element {
  width: 100%;
  height: 450px;
  border: none;
  border-radius: 8px;
  
}
.video-title, .video-header {
  margin-bottom: 10px;
  color: white;
  font-size: 1.5rem;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  text-align: center;
}
@media (max-width: 1024px) {
  .video-element {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .video-wrapper {
    max-width: 100%; 
    padding: 5px;
  }

  .video-element {
    height: 300px; 
  }

  .video-title {
    font-size: 1.2rem; 
  }
}

/* For very small devices (max-width 480px) */
@media (max-width: 480px) {
  .video-element {
    height: 200px; 
  }

  .video-title {
    font-size: 1rem; 
  }
}
