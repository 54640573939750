.serviceMainCard{
  background-color: var(--bg-color);
  padding-top: 92px !important;
  overflow-x: hidden;
  overflow-y:hidden; 
}

.service-card-main {
  margin: 0 auto;
}

.service-card {
  perspective: 1000px;
  max-width: 100%;
  
}

.service-card-inner {
  position: relative;
  width: 100%;
  min-height: 200px; 
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.service-card:hover .service-card-inner {
  transform: rotateY(180deg);
}

.service-card-front,
.service-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px groove #00fffc ;
  border-radius: 8px;
}



.service-card-back {
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  transform: rotateY(180deg);
  z-index: 1;
}

.s-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #FDFEF8;
}

.s-description {
  font-size: 1rem;
  text-align: center;
  padding: 20px;
}

.service-card.light-mode .service-card-front {
  background-color: #c2dde6; 
}

.service-card.light-mode .service-card-back {
  background-color: #F3F3F3; 
  color: black;
  font-family: Verdana, sans-serif;
}
.service-card.light-mode .service-card-front {
  background-color: #CED5D6; 
}
.service-card.light-mode .s-title {
  color: #212228;
  font-family: Verdana, sans-serif;
}


.service-card.dark-mode .service-card-front {
  background-color: #294D61; 
  color: #ffffff;
}

.service-card.dark-mode .service-card-back {
  background-color:#6DA5C0;
}



@media (max-width: 768px) {
 .s-title {
  text-align: center;
 }
}
@media (max-width: 1024px) {
 .s-title {
  text-align: center;
 }
}
