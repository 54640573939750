/* .project {
  padding-top: 80px;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.project-card {
  position: relative;
  overflow: hidden;
  border-radius: 64px 96px 128px 32px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.project-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.project-content {
  padding: 15px;
  background-color: #ffffff;
  color: #333;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;

text-align: center;
}

.project-description {
  font-size: 1rem;
  margin-bottom: 10px;
  flex: 1;
}

.tech-stack {
  margin-bottom: 2px;

}

.tech-tag {
  display: inline-block;
  background: #333;
  color: #ffffff;
  border-radius: 10px 100px/120px 100px ;
  padding: 0.3rem 0.6rem;
  font-size: 0.875rem;
  margin: 0.2rem;
}

.github-link {
  font-size: 1.5rem;
  color: #333;
  transition: color 0.3s ease;
}

.github-link:hover {
  color: #294D61;
}

@media (max-width: 768px) {
  .project-title {
    font-size: 1.25rem;
  }

}

@media (max-width: 576px) {
  .project-card {
    height: 350px; 
  }
} */
.project {
  padding-top: 80px;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.project-card {
  position: relative;
  overflow: hidden;
  border-radius: 64px 96px 128px 32px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.project-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.project-content {
  padding: 15px;
  background-color: #ffffff;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.project-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.project-description {
  font-size: 1rem;
  margin-bottom: 10px;
  flex: 1;
  text-align: center;
}

.tech-stack {
  margin-bottom: 2px;
  text-align: center;
}

.tech-tag {
  display: inline-block;
  background: #333;
  color: #ffffff;
  border-radius: 10px 100px/120px 100px;
  padding: 0.3rem 0.6rem;
  font-size: 0.875rem;
  margin: 0.2rem;
}

.github-link {
  font-size: 1.5rem;
  color: #333;
  transition: color 0.3s ease;
  text-align: center;
  margin-top: 10px;
}

.github-link:hover {
  color: #294D61;
}

@media (max-width: 768px) {
  .project-title {
    font-size: 1.25rem;
  }

  .tech-tag {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
  }

  .github-link {
    font-size: 1.25rem;
  }
}

@media (max-width: 576px) {
  .project-card {
    height: auto;
    /* padding-bottom: 15px; */
  }

  .tech-stack {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .tech-tag {
    font-size: 0.75rem;
  }

  .github-link {
    font-size: 1.25rem;
  }
}
